<template>
    <div>
        <TitleBar @return="returnBack" @save="save">{{ pageTitle }}</TitleBar>
        <div class="main-container">
            <div class="mobile-preview">
                <Emulator :title="shelfInfo.name ? shelfInfo.name : '知识货架'">
                    <div class="biz-wrapper">
                        <div v-if="shelfInfo.bindCardEnabled" class="biz-card">
                            <img src="../../../assets/avatar/avatar0.png" />
                            <div class="name-info">
                                <div class="top">
                                    <h2>Linkflow小犀</h2>
                                    <h3>售前</h3>
                                </div>
                                <h4>
                                    {{ companyName ? companyName : "未设置公司名称" }}
                                </h4>
                            </div>
                            <button><i class="nazaio-iconfont nz-id-card" />查看名片</button>
                        </div>
                        <div class="main-body">
                            <div v-if="shelfInfo.carousel.enableCarousel" class="carousel">
                                <Carousel
                                    :autoplay="shelfInfo.carousel.carouselItems.length > 1"
                                    loop
                                    :autoplay-speed="3000"
                                    v-if="shelfInfo.carousel.carouselItems.length > 0"
                                >
                                    <CarouselItem v-for="(item, index) in shelfInfo.carousel.carouselItems" :key="index">
                                        <div class="image-item" style="width: 375px; height: 193px">
                                            <img :src="item.image.src" />
                                        </div>
                                    </CarouselItem>
                                </Carousel>
                                <div v-else class="image-placeholder" style="height: 193px">
                                    <i class="nazaio-iconfont nz-image-fill"></i>
                                    <h3>待添加轮播图</h3>
                                </div>
                            </div>
                            <div class="tab-panel">
                                <div class="tab-bar" v-if="startingArticles.length > 0">
                                    <div
                                        class="tab"
                                        :class="{
                                            active: activePreviewTab === 'all',
                                        }"
                                        @click="activePreviewTab = 'all'"
                                    >
                                        <span>全部</span>
                                    </div>
                                    <div
                                        class="tab"
                                        :class="{
                                            active: activePreviewTab === 'starting',
                                        }"
                                        @click="activePreviewTab = 'starting'"
                                    >
                                        <span>即将开始</span>
                                    </div>
                                </div>
                                <ul class="category-list" v-if="activePreviewTab === 'all'">
                                    <li
                                        v-for="(category, index) in previewCategoryList"
                                        :key="category.name"
                                        :class="{
                                            active: activePreviewCategoryIndex === index,
                                        }"
                                        @click="activePreviewCategoryIndex = index"
                                    >
                                        {{ category.name }}
                                    </li>
                                </ul>
                            </div>
                            <ul class="article-list" v-if="activePreviewTab === 'all'">
                                <template v-if="previewCategoryList.length > activePreviewCategoryIndex">
                                    <li v-for="(article, index) in previewCategoryList[activePreviewCategoryIndex].articles" :key="index">
                                        <div class="info">
                                            <img :src="article | articleCoverImgFilter" />
                                            <h2>{{ article.articleTitle }}</h2>
                                        </div>
                                        <div class="time-info" v-if="article.articleType === 3">
                                            <div class="time-info-left">
                                                <i class="nazaio-iconfont nz-time-circle" /><span>{{ article | displayTimeFilter }}</span>
                                            </div>
                                            <div class="time-info-status" :class="[statusClass(article)]">
                                                {{ article | statusFilter }}
                                            </div>
                                        </div>
                                        <div class="data-bottom">
                                            <div class="data-items">
                                                <div><i class="nazaio-iconfont nz-eye"></i><span class="amount">100</span></div>
                                                <div><i class="nazaio-iconfont nz-star"></i><span class="amount">67</span></div>
                                                <div><i class="nazaio-iconfont nz-share-forward"></i><span class="amount">88</span></div>
                                            </div>
                                            <div class="fav"><i class="nazaio-iconfont nz-star"></i> 收藏</div>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                            <ul class="article-list" style="padding-top: 8px" v-else>
                                <li v-for="(article, index) in startingArticles" :key="index">
                                    <div class="info">
                                        <img :src="article | articleCoverImgFilter" />
                                        <h2>{{ article.articleTitle }}</h2>
                                    </div>
                                    <div class="time-info" v-if="article.articleType === 3">
                                        <div class="time-info-left">
                                            <i class="nazaio-iconfont nz-time-circle" /><span>{{ article | displayTimeFilter }}</span>
                                        </div>
                                        <div class="time-info-status" :class="[statusClass(article)]">
                                            {{ article | statusFilter }}
                                        </div>
                                    </div>
                                    <div class="data-bottom">
                                        <div class="data-items">
                                            <div><i class="nazaio-iconfont nz-eye"></i><span class="amount">100</span></div>
                                            <div><i class="nazaio-iconfont nz-star"></i><span class="amount">67</span></div>
                                            <div><i class="nazaio-iconfont nz-share-forward"></i><span class="amount">88</span></div>
                                        </div>
                                        <div class="fav"><i class="nazaio-iconfont nz-star"></i> 收藏</div>
                                    </div>
                                </li>
                            </ul>
                            <ul
                                class="article-list"
                                v-if="previewCategoryList.length && !previewCategoryList[activePreviewCategoryIndex].articles.length"
                            >
                                <li class="placeholder">
                                    <div class="info">
                                        <i class="nazaio-iconfont nz-image-fill"></i>
                                        <div class="line">
                                            <p></p>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div class="data-items2">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </li>
                                <li class="placeholder">
                                    <div class="info">
                                        <i class="nazaio-iconfont nz-image-fill"></i>
                                        <div class="line">
                                            <p></p>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div class="data-items2">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </li>
                                <li class="placeholder">
                                    <div class="info">
                                        <i class="nazaio-iconfont nz-image-fill"></i>
                                        <div class="line">
                                            <p></p>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div class="data-items2">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Emulator>
            </div>
            <div class="setting-panel">
                <Tabs class="setting-tabs">
                    <TabPane label="基础展示" name="basic">
                        <div class="forms">
                            <div class="form-item">
                                <label required>模板名称</label>
                                <div class="form-content">
                                    <Input v-model="shelfInfo.name" placeholder="请输入知识库名称" style="width: 436px" />
                                </div>
                            </div>
                            <div class="form-item">
                                <label required>封面图</label>
                                <div class="form-content">
                                    <Upload
                                        action=""
                                        :before-upload="uploadCoverImg"
                                        accept="image/*"
                                        :format="['jpg', 'jpeg', 'png']"
                                        :max-size="2048"
                                        style="width: 120px; height: 80px"
                                    >
                                        <div class="upload-btn" v-if="!shelfInfo.coverImg">
                                            <i class="nazaio-iconfont nz-cloud-upload" />
                                        </div>
                                        <div class="cover-preview" v-else>
                                            <img :src="shelfInfo.coverImg" />
                                        </div>
                                    </Upload>
                                    <p class="cover-desc">要求大小为432*288像素，不大于2MB</p>
                                </div>
                            </div>
                            <div class="form-item">
                                <label>是否绑定名片</label>
                                <div class="form-content">
                                    <Checkbox v-model="shelfInfo.bindCardEnabled">绑定名片模板</Checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-enable">
                            <Checkbox class="enable-checkbox" v-model="shelfInfo.carousel.enableCarousel"> 启用轮播图</Checkbox>
                            <span>最多上传四张</span>
                            <Button
                                type="primary"
                                icon="md-add-circle"
                                class="new-carousel"
                                :disabled="!shelfInfo.carousel.enableCarousel"
                                @click="newCarouselDialog"
                                >新增轮播图</Button
                            >
                        </div>
                        <Table class="carousel-table" :columns="columns" :data="shelfInfo.carousel.carouselItems">
                            <template slot-scope="{ row }" slot="image">
                                <img class="image" :src="row.image.src" />
                            </template>
                            <template slot-scope="{ row }" slot="title">
                                <span>{{ row.target.title }}</span>
                            </template>
                            <template slot-scope="{ index }" slot="action">
                                <button v-if="index > 0" @click="upCarouselSort(index)">
                                    <i class="nazaio-iconfont nz-arrow-up" />
                                </button>
                                <button v-if="index < shelfInfo.carousel.carouselItems.length - 1" @click="downCarouselSort(index)">
                                    <i class="nazaio-iconfont nz-arrow-down" />
                                </button>
                                <button @click="editCarousel(index)">
                                    <i class="nazaio-iconfont nz-edit-square" />
                                </button>
                                <button @click="removeCarousel(index)">
                                    <i class="nazaio-iconfont nz-delete" />
                                </button>
                            </template>
                        </Table>
                    </TabPane>
                    <TabPane label="内容展示" name="content">
                        <div class="category-mgr">
                            <div class="title-bar">
                                <h2>分类管理</h2>
                                <h3>最多设置六个分类</h3>
                                <Button type="primary" class="btn" @click="showCategoryMgr = true">编辑分类</Button>
                            </div>
                            <ul>
                                <li
                                    v-for="(category, index) in shelfInfo.categoryList"
                                    :key="category.name"
                                    @click="activeCategoryIndex = index"
                                    :class="{
                                        active: activeCategoryIndex === index,
                                    }"
                                >
                                    {{ category.name }}
                                </li>
                            </ul>
                        </div>
                        <div class="article-mgr">
                            <div class="title-bar">
                                <h2>{{ activeCategoryName }}</h2>
                                <div class="add-buttons">
                                    <span>添加：</span>
                                    <Button @click="addArticle(1)">轻页面</Button>
                                    <Button @click="addArticle(2)">附件</Button>
                                    <Button @click="addArticle(3)">直播活动</Button>
                                </div>
                            </div>
                            <Table class="article-table" :columns="columns3" :data="shelfInfo.categoryList[activeCategoryIndex].articles">
                                <template slot-scope="{ row }" slot="title">
                                    <span
                                        >{{ row.articleTitle
                                        }}<Tag style="margin-left: 10px" :color="row.articleType | typeFilter">{{
                                            row.articleType | typeDescFilter
                                        }}</Tag></span
                                    >
                                </template>
                                <template slot-scope="{ index }" slot="action">
                                    <button v-if="index > 0" @click="upArticleSort(index)">
                                        <i class="nazaio-iconfont nz-arrow-up" />
                                    </button>
                                    <button
                                        v-if="index < shelfInfo.categoryList[activeCategoryIndex].articles.length - 1"
                                        @click="downArticleSort(index)"
                                    >
                                        <i class="nazaio-iconfont nz-arrow-down" />
                                    </button>
                                    <button @click="editArticle(index)">
                                        <i class="nazaio-iconfont nz-edit-square" />
                                    </button>
                                    <button @click="removeArticle(index)">
                                        <i class="nazaio-iconfont nz-delete" />
                                    </button>
                                </template>
                            </Table>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </div>
        <Modal v-model="showNewCarousel" @on-visible-change="visibleChange" width="600" :title="dialogTitle" class="new-modal">
            <div class="new-form">
                <div class="form-item">
                    <label required>上传轮播图</label>
                    <div class="form-content">
                        <Upload
                            action=""
                            :before-upload="uploadNewCarouselImg"
                            accept="image/*"
                            :format="['jpg', 'jpeg', 'png']"
                            :max-size="10240"
                        >
                            <div class="upload-btn">
                                <i class="nazaio-iconfont nz-cloud-upload" />
                                <h2>点击上传</h2>
                                <h3>文件类型：jpg、png</h3>
                            </div>
                        </Upload>
                        <div v-if="newCarousel.image.src" class="file-item">
                            <i class="nazaio-iconfont nz-file-text" />
                            <h3>{{ newCarousel.image.fileName }}</h3>
                        </div>
                        <div class="remark">
                            <i class="nazaio-iconfont nz-info-circle" />
                            <p>建议上传像素为 750*386 大小的图片，支持jpg、png格式</p>
                        </div>
                    </div>
                </div>
                <div class="form-item">
                    <label required>设置对应的文章</label>
                    <div class="form-content">
                        <Select v-model="newCarousel.target.id" clearable filterable style="width: 400px" @on-change="lightPageChange">
                            <Option v-for="(lightPage, index) in lightPages" :value="lightPage.id" :key="index">{{
                                lightPage.title
                            }}</Option>
                        </Select>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <Button @click="showNewCarousel = false">取消</Button>
                <Button type="primary" @click="saveCarousel">确定</Button>
            </div>
        </Modal>
        <Modal v-model="showCategoryMgr" width="600" title="分类管理" class="category-dialog">
            <div class="category-box">
                <div class="new-category">
                    <h2>添加分类名称</h2>
                    <Input v-model="newCategoryName" clearable placeholder="请输入分类名称" style="width: 370px; margin-right: 8px" />
                    <Button type="primary" @click="addCategory">添加</Button>
                </div>
                <Table class="category-table" :columns="columns2" border :data="shelfInfo.categoryList" size="small">
                    <template slot-scope="{ index }" slot="display">
                        <i-switch @on-change="switchChange($event, index)" v-model="shelfInfo.categoryList[index].visible" size="large">
                            <span slot="open">显示</span>
                            <span slot="close">隐藏</span>
                        </i-switch>
                    </template>
                    <template slot-scope="{ index }" slot="action">
                        <button v-if="index > 0" @click="upSort(index)">
                            <i class="nazaio-iconfont nz-arrow-up" />
                        </button>
                        <button v-if="index < shelfInfo.categoryList.length - 1" @click="downSort(index)">
                            <i class="nazaio-iconfont nz-arrow-down" />
                        </button>
                        <button @click="removeCategory(index)">
                            <i class="nazaio-iconfont nz-delete" />
                        </button>
                    </template>
                </Table>
                <p><i class="nazaio-iconfont nz-info-circle" />需至少保留一个分类</p>
            </div>
            <div slot="footer">
                <Button type="primary" @click="showCategoryMgr = false">确定</Button>
            </div>
        </Modal>
        <Modal
            v-model="showNewArticleDialog"
            @on-visible-change="articleDialogVisibleChange"
            width="600"
            :title="articleDialogTitle"
            class="new-article"
        >
            <div class="new-article">
                <template v-if="newArticle.articleType === 1">
                    <div class="form-item">
                        <label required>选择轻页面</label>
                        <div class="form-content">
                            <Select
                                v-model="newArticle.articleId"
                                clearable
                                filterable
                                style="width: 380px"
                                @on-change="newArticleLightPageChange"
                                placeholder="请选择您的轻页面（支持模糊搜索）"
                            >
                                <Option v-for="(lightPage, index) in lightPages" :value="lightPage.id" :key="index">{{
                                    lightPage.title
                                }}</Option>
                            </Select>
                        </div>
                    </div>
                </template>
                <template v-if="newArticle.articleType === 2">
                    <div class="form-item">
                        <label required>上传附件</label>
                        <div class="form-content">
                            <Upload
                                action=""
                                :before-upload="uploadAttachment"
                                accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.mp4,"
                                :format="['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'mp4']"
                                :max-size="10240"
                            >
                                <div class="upload-btn2">
                                    <i class="nazaio-iconfont nz-cloud-upload" />
                                    <h2>点击上传</h2>
                                    <h3>文件类型：word、ppt、excel、pdf、mp4</h3>
                                </div>
                            </Upload>
                            <div v-if="newArticle.articleUrl" class="file-item">
                                <i class="nazaio-iconfont nz-file-text" />
                                <h3>{{ newArticle.fileName }}</h3>
                            </div>
                            <div class="remark">
                                <i class="nazaio-iconfont nz-info-circle" />
                                <p>建议上传文件大小不超过20M</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-item">
                        <label required>附件标题</label>
                        <div class="form-content">
                            <Input v-model="newArticle.articleTitle" placeholder="请输入附件标题" style="width: 380px" clearable />
                        </div>
                    </div>
                </template>
                <template v-if="newArticle.articleType === 3">
                    <div class="tip-info">
                        <i class="nazaio-iconfont nz-info-circle-fill" />
                        <p>如果想要增加直播的曝光率，您可以在轻页面中添加真实的直播活动链接，用户再通过浏览器打开链接参与直播互动。</p>
                    </div>
                    <div class="form-item" style="margin-top: 24px">
                        <label required>直播活动</label>
                        <div class="form-content">
                            <Select
                                v-model="newArticle.articleId"
                                clearable
                                filterable
                                style="width: 380px"
                                @on-change="newArticleLightPageChange"
                                placeholder="请选择您的轻页面（支持模糊搜索）"
                            >
                                <Option v-for="(lightPage, index) in lightPages" :value="lightPage.id" :key="index">{{
                                    lightPage.title
                                }}</Option>
                            </Select>
                        </div>
                    </div>
                    <div class="form-item" style="margin-top: 24px">
                        <label required>选择直播时间区间</label>
                        <div class="form-content">
                            <DatePicker
                                type="datetimerange"
                                format="yyyy-MM-dd HH:mm"
                                placeholder="请选择直播时间区间"
                                style="width: 380px"
                                v-model="timeRange"
                            ></DatePicker>
                        </div>
                    </div>
                </template>
            </div>
            <div slot="footer">
                <Button @click="showNewArticleDialog = false">取消</Button>
                <Button type="primary" @click="saveArticle">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import TitleBar from "./components/TitleBar";
import { get, getCompanyInfo, save } from "@/api/knowledge-shelf";
import { ossCmsUpload } from "@/util/oss";
import { getList } from "@/api/lightpage";
import Emulator from "../components/Emulator";

Date.prototype.format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        S: this.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
    return fmt;
};

export default {
    components: {
        TitleBar,
        Emulator,
    },
    created() {
        this.shelfInfo.id = this.$route.params.id;
        if (this.shelfInfo.id) {
            get(this.shelfInfo.id).then((response) => {
                this.shelfInfo.name = response.name;
                this.shelfInfo.coverImg = response.coverImg;
                this.shelfInfo.bindCardEnabled = response.bindCardEnabled;
                this.shelfInfo.carousel = JSON.parse(response.carousel);
                this.shelfInfo.categoryList = response.categoryList;
                this.watchData();
            });
        } else {
            this.watchData();
        }
        getCompanyInfo().then((response) => {
            this.companyName = response.companyName;
        });
        this.loadLightPage();
    },
    beforeRouteLeave(to, from, next) {
        if (this.changed) {
            this.$Modal.confirm({
                title: "确定返回上一页吗？",
                content: "<p>您还没有保存编辑，是否确定返回到上一页？</p>",
                onCancel: () => {
                    next(false);
                },
                onOk: () => {
                    next();
                },
            });
        } else {
            next();
        }
    },
    filters: {
        typeFilter(type) {
            switch (type) {
                case 1:
                    return "cyan";
                case 2:
                    return "orange";
                case 3:
                    return "blue";
            }
        },
        typeDescFilter(type) {
            switch (type) {
                case 1:
                    return "轻页面";
                case 2:
                    return "附件";
                case 3:
                    return "直播";
            }
        },
        articleCoverImgFilter(article) {
            if (article.articleType === 2) {
                let dotIndex = article.fileName.lastIndexOf(".");
                if (dotIndex >= 0) {
                    let type = article.fileName.substr(dotIndex + 1, article.fileName.length, dotIndex).toLowerCase();
                    return "/file-icons/file-icon-" + type + ".png";
                }
            }
            return article.coverImg;
        },
        displayTimeFilter(article) {
            return new Date(article.timeStart).format("yyyy-MM-dd hh:mm") + " ~ " + new Date(article.timeEnd).format("yyyy-MM-dd hh:mm");
        },
        statusFilter(article) {
            let currentTime = new Date();
            if (new Date(article.timeStart) > currentTime) {
                return "● 未开始";
            } else if (new Date(article.timeEnd) < currentTime) {
                return "● 已结束";
            } else {
                return "● 进行中";
            }
        },
    },
    data() {
        return {
            shelfInfo: {
                id: 0,
                name: "",
                coverImg: "",
                bindCardEnabled: false,
                carousel: {
                    enableCarousel: false,
                    carouselItems: [],
                },
                categoryList: [
                    {
                        id: 1,
                        name: "默认分类",
                        visible: true,
                        articles: [],
                    },
                ],
            },
            changed: false,
            activePreviewCategoryIndex: 0,
            activePreviewTab: "all",
            columns: [
                {
                    title: "排序（从左至右）",
                    type: "index",
                    width: 150,
                },
                {
                    title: "轮播图",
                    slot: "image",
                    width: 300,
                    align: "center",
                },
                {
                    title: "文章标题",
                    slot: "title",
                },
                {
                    title: "操作",
                    slot: "action",
                    align: "center",
                    width: 160,
                },
            ],
            columns2: [
                {
                    title: "排序",
                    type: "index",
                    width: 100,
                },
                {
                    title: "分类名称",
                    key: "name",
                    align: "left",
                },
                {
                    title: "是否显示",
                    slot: "display",
                    align: "center",
                    width: 120,
                },
                {
                    title: "操作",
                    slot: "action",
                    align: "center",
                    width: 140,
                },
            ],
            columns3: [
                {
                    title: "文章标题",
                    slot: "title",
                    align: "left",
                },
                {
                    title: "操作",
                    slot: "action",
                    align: "center",
                    width: 160,
                },
            ],
            companyName: "",
            displayTab: "all",
            showNewCarousel: false,
            newCarousel: {
                image: {
                    src: "",
                    fileName: "",
                },
                target: {
                    id: null,
                    title: "",
                },
            },
            lightPages: [],
            editDialogIndex: -1,
            newCategoryName: "",
            showCategoryMgr: false,
            activeCategoryIndex: 0,
            showNewArticleDialog: false,
            editArticleIndex: -1,
            articleType: "",
            newArticle: {
                id: 0,
                articleType: 1,
                articleId: 0,
                articleTitle: "",
                articleUrl: "",
                coverImg: "",
                fileName: "",
                timeStart: "",
                timeEnd: "",
            },
            timeRange: "",
        };
    },
    computed: {
        pageTitle() {
            if (this.shelfInfo.id) {
                return "编辑知识货架";
            } else {
                return "新建知识货架";
            }
        },
        dialogTitle() {
            if (this.editDialogIndex < 0) {
                return "新增轮播图";
            }
            return "编辑轮播图";
        },
        articleDialogTitle() {
            let suffix = "";
            switch (this.newArticle.articleType) {
                case 1:
                    suffix = "轻页面";
                    break;
                case 2:
                    suffix = "附件";
                    break;
                case 3:
                    suffix = "直播活动";
                    break;
            }
            if (this.editArticleIndex < 0) {
                return "新增" + suffix;
            }
            return "编辑" + suffix;
        },
        activeCategoryName() {
            return this.shelfInfo.categoryList[this.activeCategoryIndex].name;
        },
        previewCategoryList() {
            return this.shelfInfo.categoryList.filter((c) => c.visible);
        },
        startingArticles() {
            let articles = [];
            this.shelfInfo.categoryList.forEach((c) => {
                if (c.visible) {
                    c.articles.forEach((a) => {
                        if (a.articleType === 3 && a.timeStart) {
                            if (new Date(a.timeStart) > new Date()) {
                                articles.push(a);
                            }
                        }
                    });
                }
            });
            return articles;
        },
    },
    methods: {
        statusClass(article) {
            let currentTime = new Date();
            if (new Date(article.timeStart) > currentTime) {
                return "starting";
            } else if (new Date(article.timeEnd) < currentTime) {
                return "end";
            } else {
                return "living";
            }
        },
        returnBack() {
            this.$router.go(-1);
        },
        watchData() {
            this.$watch("shelfInfo", {
                handler: () => {
                    this.changed = true;
                },
                deep: true,
            });
        },
        reloadData() {
            get(this.shelfInfo.id).then((response) => {
                this.shelfInfo.name = response.name;
                this.shelfInfo.coverImg = response.coverImg;
                this.shelfInfo.bindCardEnabled = response.bindCardEnabled;
                this.shelfInfo.carousel = JSON.parse(response.carousel);
                this.shelfInfo.categoryList = response.categoryList;
            });
        },
        switchChange(visible, index) {
            if (!visible) {
                if (this.shelfInfo.categoryList.filter((c) => c.visible).length === 0) {
                    this.$Message.error("至少显示一个分类！");
                    this.$nextTick(() => (this.shelfInfo.categoryList[index].visible = true));
                }
            }
        },
        uploadCoverImg(file) {
            ossCmsUpload(file, (result) => {
                this.$Message.success("上传完成！");
                if (result.resourceUrl) {
                    this.shelfInfo.coverImg = result.resourceUrl + "/" + result.Key;
                } else {
                    this.shelfInfo.coverImg = result.Location;
                }
            });
            return false;
        },
        uploadNewCarouselImg(file) {
            ossCmsUpload(file, (result) => {
                this.$Message.success("上传完成！");
                if (result.resourceUrl) {
                    this.newCarousel.image.src = result.resourceUrl + "/" + result.Key;
                } else {
                    this.newCarousel.image.src = result.Location;
                }
                this.newCarousel.image.fileName = result.fileName;
            });
            return false;
        },
        uploadNewThirdLinkImg(file) {
            ossCmsUpload(file, (result) => {
                this.$Message.success("上传完成！");
                if (result.resourceUrl) {
                    this.newArticle.coverImg = result.resourceUrl + "/" + result.Key;
                } else {
                    this.newArticle.coverImg = result.Location;
                }
            });
            return false;
        },
        uploadAttachment(file) {
            ossCmsUpload(file, (result) => {
                this.$Message.success("上传完成！");
                if (result.resourceUrl) {
                    this.newArticle.articleUrl = result.resourceUrl + "/" + result.Key;
                } else {
                    this.newArticle.articleUrl = result.Location;
                }
                this.newArticle.fileName = result.fileName;
            });
            return false;
        },
        resetNewCarousel() {
            this.newCarousel = {
                image: {
                    src: "",
                    fileName: "",
                },
                target: {
                    id: null,
                    title: "",
                },
            };
            this.editDialogIndex = -1;
        },
        resetNewArticle() {
            this.newArticle = {
                id: 0,
                articleType: 1,
                articleId: 0,
                articleTitle: "",
                articleUrl: "",
                coverImg: "",
                fileName: "",
                timeStart: "",
                timeEnd: "",
            };
            this.timeRange = "";
            this.editArticleIndex = -1;
        },
        visibleChange(visible) {
            if (!visible) {
                this.resetNewCarousel();
            }
        },
        articleDialogVisibleChange(visible) {
            if (!visible) {
                this.resetNewArticle();
            }
        },
        newCarouselDialog() {
            if (this.shelfInfo.carousel.carouselItems.length >= 4) {
                this.$Message.error("轮播图上传数量已达上限！");
                return;
            }
            this.showNewCarousel = true;
        },
        lightPageChange(selectId) {
            let selectLightPage = this.lightPages.find((l) => l.id === selectId);
            if (selectLightPage) {
                this.newCarousel.target.title = selectLightPage.title;
            } else {
                this.newCarousel.target.title = "";
            }
        },
        newArticleLightPageChange(selectId) {
            let selectLightPage = this.lightPages.find((l) => l.id === selectId);
            if (selectLightPage) {
                this.newArticle.articleId = selectLightPage.id;
                this.newArticle.articleTitle = selectLightPage.title;
                this.newArticle.coverImg = selectLightPage.coverImg;
            } else {
                this.newArticle.articleId = 0;
                this.newArticle.articleTitle = "";
                this.newArticle.coverImg = "";
            }
        },
        useThirdLink() {
            this.newCarousel.type = "thirdLink";
            this.newCarousel.target.id = null;
            this.newCarousel.target.title = "";
        },
        useLightPage() {
            this.newCarousel.type = "lightPage";
            this.newCarousel.target.id = null;
            this.newCarousel.target.url = "";
            this.newCarousel.target.title = "";
        },
        saveCarousel() {
            if (this.editDialogIndex < 0) {
                if (this.shelfInfo.carousel.carouselItems.length >= 4) {
                    this.$Message.error("轮播图上传数量已达上限！");
                    return;
                }
            }

            if (!this.newCarousel.image.src) {
                this.$Message.error("请上传轮播图！");
                return;
            }

            if (this.newCarousel.type === "lightPage" && !this.newCarousel.target.id) {
                this.$Message.error("请选择轻页面！");
                return;
            }

            if (this.newCarousel.type === "thirdLink" && !this.newCarousel.target.url) {
                this.$Message.error("请填写外部链接URL！");
                return;
            }

            if (this.newCarousel.type === "thirdLink" && !this.newCarousel.target.title) {
                this.$Message.error("请填写外部链接标题！");
                return;
            }

            if (this.editDialogIndex < 0) {
                this.shelfInfo.carousel.carouselItems.push(JSON.parse(JSON.stringify(this.newCarousel)));
            } else {
                this.shelfInfo.carousel.carouselItems.splice(this.editDialogIndex, 1, JSON.parse(JSON.stringify(this.newCarousel)));
            }
            this.showNewCarousel = false;
        },
        loadLightPage() {
            getList({ status: 2, pageNum: 1, pageSize: 0 }).then((response) => {
                this.lightPages = response.data;
            });
        },
        editCarousel(index) {
            this.editDialogIndex = index;
            this.newCarousel = JSON.parse(JSON.stringify(this.shelfInfo.carousel.carouselItems[index]));
            this.showNewCarousel = true;
        },
        removeCarousel(index) {
            this.shelfInfo.carousel.carouselItems.splice(index, 1);
        },
        addCategory() {
            if (this.shelfInfo.categoryList.length >= 6) {
                this.$Message.error("最多只能设置六个分类！");
                return;
            }
            if (!this.newCategoryName) {
                this.$Message.error("请输入分类名称！");
                return;
            }
            if (this.shelfInfo.categoryList.findIndex((c) => c.name === this.newCategoryName) >= 0) {
                this.$Message.error("已存在该分类名称！");
                return;
            }
            this.shelfInfo.categoryList.push({
                name: this.newCategoryName,
                visible: true,
                articles: [],
            });
            this.newCategoryName = null;
        },
        upSort(index) {
            let previous = this.shelfInfo.categoryList[index - 1];
            this.shelfInfo.categoryList.splice(index - 1, 1);
            this.shelfInfo.categoryList.splice(index, 0, previous);
        },
        downSort(index) {
            let next = this.shelfInfo.categoryList[index + 1];
            this.shelfInfo.categoryList.splice(index + 1, 1);
            this.shelfInfo.categoryList.splice(index, 0, next);
        },
        upCarouselSort(index) {
            let previous = this.shelfInfo.carousel.carouselItems[index - 1];
            this.shelfInfo.carousel.carouselItems.splice(index - 1, 1);
            this.shelfInfo.carousel.carouselItems.splice(index, 0, previous);
        },
        downCarouselSort(index) {
            let next = this.shelfInfo.carousel.carouselItems[index + 1];
            this.shelfInfo.carousel.carouselItems.splice(index + 1, 1);
            this.shelfInfo.carousel.carouselItems.splice(index, 0, next);
        },
        upArticleSort(index) {
            let previous = this.shelfInfo.categoryList[this.activeCategoryIndex].articles[index - 1];
            this.shelfInfo.categoryList[this.activeCategoryIndex].articles.splice(index - 1, 1);
            this.shelfInfo.categoryList[this.activeCategoryIndex].articles.splice(index, 0, previous);
        },
        downArticleSort(index) {
            let next = this.shelfInfo.categoryList[this.activeCategoryIndex].articles[index + 1];
            this.shelfInfo.categoryList[this.activeCategoryIndex].articles.splice(index + 1, 1);
            this.shelfInfo.categoryList[this.activeCategoryIndex].articles.splice(index, 0, next);
        },
        removeCategory(index) {
            if (this.shelfInfo.categoryList.length === 1) {
                this.$Message.error("至少保留一个分类！");
                return;
            }
            this.activeCategoryIndex = 0;
            this.activePreviewCategoryIndex = 0;
            this.shelfInfo.categoryList.splice(index, 1);
        },
        addArticle(type) {
            this.newArticle.articleType = type;
            this.showNewArticleDialog = true;
        },
        editArticle(index) {
            this.editArticleIndex = index;
            this.newArticle = JSON.parse(JSON.stringify(this.shelfInfo.categoryList[this.activeCategoryIndex].articles[index]));
            if (this.newArticle.timeStart && this.newArticle.timeEnd) {
                this.timeRange = [new Date(this.newArticle.timeStart), new Date(this.newArticle.timeEnd)];
            }
            this.showNewArticleDialog = true;
        },
        removeArticle(index) {
            this.shelfInfo.categoryList[this.activeCategoryIndex].articles.splice(index, 1);
        },
        saveArticle() {
            if (this.newArticle.articleType === 1) {
                if (!this.newArticle.articleId) {
                    this.$Message.error("请选择轻页面！");
                    return;
                }
            } else if (this.newArticle.articleType === 2) {
                if (!this.newArticle.articleUrl) {
                    this.$Message.error("请上传附件！");
                    return;
                }
                if (!this.newArticle.articleTitle) {
                    this.$Message.error("请填写附件标题！");
                    return;
                }
            } else if (this.newArticle.articleType === 3) {
                if (!this.newArticle.articleId) {
                    this.$Message.error("请选择直播活动！");
                    return;
                }
                if (!this.newArticle.timeStart || !this.newArticle.timeEnd) {
                    this.$Message.error("请设置直播时间区间！");
                    return;
                }
            }

            if (this.editArticleIndex < 0) {
                this.shelfInfo.categoryList[this.activeCategoryIndex].articles.push(JSON.parse(JSON.stringify(this.newArticle)));
            } else {
                this.shelfInfo.categoryList[this.activeCategoryIndex].articles.splice(
                    this.editArticleIndex,
                    1,
                    JSON.parse(JSON.stringify(this.newArticle))
                );
            }
            this.showNewArticleDialog = false;
        },
        save() {
            if (!this.shelfInfo.name) {
                this.$Message.error("模板名称不能为空！");
                return;
            }
            if (!this.shelfInfo.coverImg) {
                this.$Message.error("封面图不能为空！");
                return;
            }
            let data = JSON.parse(JSON.stringify(this.shelfInfo));
            data.carousel = JSON.stringify(data.carousel);
            save(data).then((response) => {
                this.$Message.success("保存成功！");
                this.$nextTick(() => {
                    this.shelfInfo.id = response;
                    this.changed = false;
                    this.$router.replace("/social-card/knowledge-shelf/edit/" + response);
                });
            });
        },
    },
    watch: {
        timeRange(val) {
            if (val && val.length === 2) {
                if (val[0] instanceof Date) {
                    this.newArticle.timeStart = val[0].format("yyyy-MM-dd hh:mm:ss");
                }
                if (val[1] instanceof Date) {
                    this.newArticle.timeEnd = val[1].format("yyyy-MM-dd hh:mm:ss");
                }
            } else {
                this.newArticle.timeStart = "";
                this.newArticle.timeEnd = "";
            }
        },
    },
};
</script>
<style lang="less" scoped>
.main-container {
    display: flex;
}

.mobile-preview {
    width: 431px;
    flex: 0 0 431px;
    // margin-bottom: -20px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d4dbe1;
        border-radius: 3px;
    }

    .biz-wrapper {
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
    }

    .biz-card {
        display: flex;
        background-color: #fff;
        z-index: 2;
        flex: 0 0 auto;
        box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.2);

        img {
            flex: 0 0 40px;
            height: 40px;
            margin: 20px 0 20px 20px;
        }

        .name-info {
            margin-left: 16px;
            margin-top: 16px;
            width: 154px;

            .top {
                display: flex;

                h2 {
                    font-size: 17px;
                    color: #000;
                    margin: 0;
                    height: 24px;
                    line-height: 24px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                h3 {
                    font-size: 14px;
                    font-weight: normal;
                    margin: 0 0 0 16px;
                    height: 24px;
                    line-height: 24px;
                    overflow: hidden;
                }
            }

            h4 {
                margin: 4px 0 16px 0;
                font-size: 12px;
                font-weight: normal;
                color: rgba(0, 0, 0, 0.5);
                line-height: 18px;
            }
        }

        button {
            width: 108px;
            height: 32px;
            outline: none;
            border: none;
            margin-top: 23px;
            margin-left: 16px;
            background-color: #07c160;
            color: #fff;
            font-size: 15px;
            border-radius: 4px;

            i {
                margin-right: 8px;
            }
        }
    }

    .main-body {
        flex: 1 1 auto;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #d4dbe1;
            border-radius: 3px;
        }
    }

    .carousel {
        .image-item {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                display: block;
                min-width: 100%;
                min-height: 100%;
            }
        }
    }

    .image-placeholder {
        background-color: #e8eaec;
        height: 193px;
        overflow: hidden;

        i {
            display: block;
            font-size: 60px;
            text-align: center;
            margin-top: 40px;
            height: 60px;
            color: #c6c9cf;
        }

        h3 {
            text-align: center;
            font-size: 12px;
            font-weight: normal;
            color: #8494a6;
            margin-top: 14px;
        }
    }

    .tab-panel {
        .tab-bar {
            display: flex;
            background-color: #fff;

            .tab {
                width: 50%;
                text-align: center;
                font-size: 15px;
                font-weight: normal;
                color: rgba(0, 0, 0, 0.5);
                line-height: 40px;
                height: 40px;
                box-sizing: border-box;
                cursor: pointer;

                &.active span {
                    font-weight: bold;
                    padding-bottom: 9px;
                    color: #000;
                    border-bottom: 3px solid @fc-main-color;
                }
            }
        }

        .category-list {
            list-style: none;
            padding: 0;
            background-color: #f2f4f7;
            overflow-x: auto;
            white-space: nowrap;

            &::-webkit-scrollbar {
                height: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #d4dbe1;
                border-radius: 3px;
            }

            li {
                display: inline-block;
                height: 40px;
                line-height: 40px;
                font-size: 14px;
                color: #000;
                padding: 0 16px;
                cursor: pointer;

                &.active {
                    color: @fc-main-color;
                }
            }
        }
    }

    .article-list {
        list-style: none;
        padding: 0;
        background-color: #f2f4f7;

        li {
            margin-bottom: 8px;
            background-color: #fff;
            overflow: hidden;
        }

        .info {
            display: flex;
            margin: 16px 16px 0 16px;

            img {
                flex: 0 0 44px;
                height: 44px;
                width: 44px;
            }

            h2 {
                margin-left: 12px;
                font-size: 15px;
                left: 22px;
            }
        }

        .time-info {
            display: flex;
            align-items: center;
            margin-top: 9px;
            padding: 0 16px;

            .time-info-left {
                flex: 1 1 auto;
                height: 18px;
                line-height: 18px;
                color: rgba(0, 0, 0, 0.3);
                font-size: 12px;
            }

            i {
                color: #07c160;
                margin-right: 4px;
                font-size: 14px;
            }

            .time-info-status {
                flex: 0 0 auto;
                width: 50px;
                font-size: 12px;
                height: 18px;
                line-height: 18px;
                text-align: right;

                &.starting {
                    color: #00b2a6;
                }

                &.end {
                    color: #8494a6;
                }

                &.living {
                    color: #ff9900;
                }
            }
        }

        .data-bottom {
            height: 40px;
            display: flex;
            align-items: center;
            padding: 0 16px;
            margin-top: 13px;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
        }

        .data-items {
            flex: 1 1 auto;
            display: flex;
            align-items: center;

            & > div {
                flex: 1 1 auto;
                text-align: center;
                color: rgba(0, 0, 0, 0.5);
            }

            i {
                margin-right: 8px;
                vertical-align: middle;
            }

            .amount {
                line-height: 16px;
                vertical-align: middle;
            }
        }

        .data-items2 {
            display: flex;
            margin: 6px 0 12px 0;
            height: 16px;

            & > div {
                flex: 1 1 auto;
                text-align: center;
                color: rgba(0, 0, 0, 0.5);
            }
        }

        .fav {
            flex: 0 0 auto;
            margin-left: 19px;
            width: 60px;
            height: 24px;
            border-radius: 3px;
            background-color: #07c160;
            color: #fff;
            text-align: center;
            font-size: 12px;
        }

        .placeholder {
            .info {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                display: flex;
                padding-bottom: 16px;
            }

            i {
                width: 44px;
                height: 44px;
                background-color: rgba(232, 234, 236, 1);
                text-align: center;
                line-height: 44px;
                color: rgba(198, 201, 207, 1);
                font-size: 22px;
                flex: 0 0 auto;
            }

            .line {
                flex: 1 1 auto;
                margin-left: 12px;

                p {
                    margin: 0;
                    height: 18px;
                    background-color: #eff2f5;
                    margin-bottom: 8px;

                    &:last-child {
                        width: 40%;
                        margin-bottom: 0;
                    }
                }
            }

            .data-items2 {
                display: flex;
                justify-content: space-evenly;

                div {
                    width: 46px;
                    height: 12px;
                    border-radius: 2;
                    background-color: #eff2f5;
                    flex: 0 0 auto;
                }
            }
        }
    }
}

.setting-panel {
    background-color: #fff;
    margin-left: 10px;
    flex: 1 1 auto;
    border-radius: 4px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d4dbe1;
        border-radius: 3px;
    }
}

.setting-tabs {
    margin-top: 8px;

    .forms {
        margin: 20px 20px;
    }

    .form-item {
        display: flex;
        margin-top: 12px;

        label {
            flex: 0 0 122px;
            font-size: 14px;
            color: #0a2a4c;
            text-align: right;
            height: 32px;
            line-height: 32px;

            &[required]:after {
                content: "*";
                color: #ff0000;
                font-size: 14px;
                margin-left: 6px;
            }
        }

        .form-content {
            flex: 1 1 auto;
            margin-left: 16px;
        }
    }

    .cover-preview {
        width: 120px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        cursor: pointer;

        img {
            display: block;
            min-width: 100%;
            min-height: 100%;
        }
    }

    .upload-btn {
        border: 1px dashed #d4dbe1;
        width: 120px;
        height: 80px;
        cursor: pointer;
        border-radius: 3px;

        &:hover {
            border-color: @fc-main-color;
        }

        i {
            font-size: 45px;
            display: block;
            margin: 6px auto 0 auto;
            color: #8494a6;
            text-emphasis: center;
            text-align: center;
        }
    }

    .cover-desc {
        margin-top: 8px;
        font-size: 12px;
        color: #8494a6;
    }

    .carousel-enable {
        height: 32px;
        line-height: 32px;
        margin: 30px 20px 0 20px;
        overflow: hidden;

        .enable-checkbox {
            float: left;
        }

        span {
            float: left;
            font-size: 12px;
            color: #8494a6;
        }

        .new-carousel {
            float: right;
        }
    }

    .carousel-table {
        margin: 13px 20px;

        button {
            background-color: transparent;
            border: none;
            outline: none;
            cursor: pointer;
            width: 20px;
            line-height: 20px;
            height: 20px;
            color: #395069;
            margin: 0 5px;

            &:hover {
                color: #ed4014;
            }
        }
    }

    .image {
        max-width: 100%;
        max-height: 76px;
        margin: 16px auto;
        display: block;
    }

    .category-mgr,
    .article-mgr {
        margin: 20px;
    }

    .title-bar {
        overflow: hidden;

        h2 {
            font-size: 16px;
            color: #0a2a4c;
            height: 32px;
            line-height: 32px;
            margin: 0;
            float: left;
        }

        h3 {
            font-size: 12px;
            margin: 0 0 0 16px;
            font-weight: normal;
            color: #8494a6;
            float: left;
            height: 32px;
            line-height: 32px;
        }

        .btn {
            float: right;
        }

        .add-buttons {
            float: right;

            & > * {
                margin-left: 8px;
            }
        }
    }

    .category-mgr {
        ul {
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            border-bottom: 1px solid #e8eaec;

            li {
                height: 32px;
                box-sizing: border-box;
                text-align: center;
                font-size: 14px;
                color: #395069;
                background-color: #f2f4f7;
                line-height: 32px;
                border-radius: 16px;
                padding: 0 20px;
                cursor: pointer;
                margin: 16px 8px 20px 0;

                &:last-child {
                    margin-right: 0;
                }

                &.active {
                    border: 1px solid #41ccba;
                    color: #00b2a6;
                    background-color: #daf2ed;
                    line-height: 30px;
                }
            }
        }
    }

    .article-mgr {
        margin-top: 20px;
    }

    .article-table {
        margin-top: 16px;

        button {
            background-color: transparent;
            border: none;
            outline: none;
            cursor: pointer;
            width: 20px;
            line-height: 20px;
            height: 20px;
            color: #395069;
            margin: 0 5px;

            &:hover {
                color: #ed4014;
            }
        }
    }
}

.setting-tabs /deep/ .ivu-tabs-nav {
    margin-left: 20px;

    .ivu-tabs-tab-active {
        font-weight: 700;
    }
}

.new-modal {
    .new-form {
        margin: 0 0 30px 0;
    }

    .form-item {
        display: flex;
        margin-top: 12px;

        label {
            flex: 0 0 122px;
            font-size: 14px;
            color: #0a2a4c;
            text-align: right;
            height: 32px;
            line-height: 32px;
        }

        .form-content {
            flex: 1 1 auto;
            margin-left: 16px;
        }
    }

    .upload-btn {
        border: 1px dashed #d4dbe1;
        width: 240px;
        height: 110px;
        cursor: pointer;

        &:hover {
            border-color: @fc-main-color;
        }

        i {
            font-size: 31px;
            width: 32px;
            height: 32px;
            display: block;
            margin: 10px auto 0 auto;
            color: @fc-main-color;
        }

        h2 {
            text-align: center;
            font-size: 14px;
            height: 22px;
            line-height: 22px;
            margin: 10px 0 0 0;
            font-weight: normal;
            color: #395069;
        }

        h3 {
            text-align: center;
            font-size: 12px;
            color: #8494a6;
            margin: 0 0 16px 0;
            font-weight: normal;
            height: 18px;
            line-height: 18px;
        }
    }

    .file-item {
        margin-top: 8px;
        position: relative;
        overflow: hidden;
        height: 28px;
        line-height: 28px;

        &:hover {
            background-color: #f8f8f8;
        }

        .nz-file-text {
            color: #8494a6;
            float: left;
        }

        h3 {
            font-weight: normal;
            margin: 0;
            text-align: left;
            color: #395069;
            position: absolute;
            left: 26px;
            right: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
        }
    }

    .remark {
        display: flex;
        margin-top: 5px;

        i {
            color: @fc-main-color;
            width: 16px;
        }

        p {
            margin: 3px 0 0 10px;
            font-size: 12px;
            color: #8494a6;
            line-height: 18px;
        }
    }

    p {
        font-size: 12px;
        color: #8494a6;
        margin-top: 8px;

        i {
            font-size: 14px;
            color: #ff9900;
            margin-right: 9px;
        }

        span {
            color: @fc-main-color;
            cursor: pointer;
        }
    }

    .third-link {
        border: 1px solid #e8eaec;
        border-radius: 5px;
        width: 400px;
        position: relative;
        padding: 16px 20px;

        button {
            position: absolute;
            width: 32px;
            height: 32px;
            border: 1px solid #d4dbe1;
            border-radius: 16px;
            box-sizing: border-box;
            outline: none;
            line-height: 32px;
            font-size: 16px;
            right: -16px;
            top: -16px;
            background-color: #fff;
            color: #ed4014;
            cursor: pointer;

            &:hover {
                background-color: #ed4014;
                color: #fff;
            }
        }
    }
}

.category-dialog {
    .category-box {
        padding: 8px;
    }

    .new-category {
        overflow: hidden;

        h2 {
            float: left;
            font-size: 14px;
            font-weight: normal;
            height: 32px;
            line-height: 32px;
            margin-left: 12px;
            color: #0a2a4c;
            margin-right: 16px;
        }
    }

    .category-table {
        margin-top: 12px;

        button {
            background-color: transparent;
            border: none;
            outline: none;
            cursor: pointer;
            width: 20px;
            line-height: 20px;
            height: 20px;
            color: #395069;
            margin: 0 4px;

            &:hover {
                color: #ed4014;
            }
        }
    }

    p {
        font-size: 12px;
        margin-top: 8px;
        color: #8494a6;

        i {
            color: @fc-main-color;
            margin-right: 8px;
        }
    }
}

.new-article {
    margin: 0 24px 10px 24px;

    .form-item {
        display: flex;
        margin-top: 12px;

        label {
            flex: 0 0 126px;
            font-size: 14px;
            color: #0a2a4c;
            text-align: right;
            height: 32px;
            line-height: 32px;

            &[required]:after {
                content: "*";
                color: #ff0000;
                font-size: 14px;
                margin-left: 6px;
            }
        }

        .form-content {
            flex: 1 1 auto;
            margin-left: 16px;
        }
    }

    .upload-btn {
        width: 100px;
        height: 100px;
        box-sizing: border-box;
        border: 1px dashed #d4dbe1;
        color: #8494a6;
        cursor: pointer;

        &:hover {
            border-color: @fc-main-color;
        }

        i {
            font-size: 42px;
            text-align: center;
            display: block;
            margin-top: 16px;
        }
    }

    .preview-img {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        cursor: pointer;

        img {
            display: block;
            min-width: 100%;
            min-height: 100%;
        }
    }

    p {
        margin-top: 8px;
        color: #8494a6;
        font-size: 12px;
    }

    .upload-btn2 {
        border: 1px dashed #d4dbe1;
        width: 240px;
        height: 110px;
        cursor: pointer;

        &:hover {
            border-color: @fc-main-color;
        }

        i {
            font-size: 31px;
            width: 32px;
            height: 32px;
            display: block;
            margin: 10px auto 0 auto;
            color: @fc-main-color;
        }

        h2 {
            text-align: center;
            font-size: 14px;
            height: 22px;
            line-height: 22px;
            margin: 10px 0 0 0;
            font-weight: normal;
            color: #395069;
        }

        h3 {
            text-align: center;
            font-size: 12px;
            color: #8494a6;
            margin: 0 0 16px 0;
            font-weight: normal;
            height: 18px;
            line-height: 18px;
        }
    }

    .file-item {
        margin-top: 8px;
        position: relative;
        overflow: hidden;
        height: 28px;
        line-height: 28px;

        &:hover {
            background-color: #f8f8f8;
        }

        .nz-file-text {
            color: #8494a6;
            float: left;
        }

        h3 {
            font-weight: normal;
            margin: 0;
            text-align: left;
            color: #395069;
            position: absolute;
            left: 26px;
            right: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
        }
    }

    .remark {
        display: flex;
        margin-top: 5px;

        i {
            color: @fc-main-color;
            width: 16px;
        }

        p {
            margin: 3px 0 0 10px;
            font-size: 12px;
            color: #8494a6;
            line-height: 18px;
        }
    }

    .tip-info {
        display: flex;
        padding: 9px 16px;
        background-color: rgba(0, 178, 166, 0.1);
        border: 1px solid #34c2b8;
        border-radius: 3px;

        i {
            color: @fc-main-color;
        }

        p {
            font-size: 14px;
            color: #395069;
            line-height: 26px;
            margin: 0 0 0 10px;
        }
    }
}
</style>