import request from '@/util/request';
import { requestRaw } from '@/util/request';

export function getList(query) {
    return request({
        url: '/knowledge-shelf',
        method: 'get',
        params: query
    });
};

export function get(id) {
    return request({
        url: `/knowledge-shelf/${id}`,
        method: 'get'
    });
}

export function getCompanyInfo() {
    return request({
        url: `/knowledge-shelf/company-info`,
        method: 'get'
    });
}

export function save(data) {
    return request({
        url: '/knowledge-shelf',
        method: 'post',
        data: data
    });
}

export function remove(id) {
    return request({
        url: `/knowledge-shelf/${id}`,
        method: 'delete'
    });
}

export function getSummaryInfo(id, query) {
    return request({
        url: `/knowledge-shelf/report/${id}/summary-info`,
        method: 'get',
        params: query
    });
};

export function getEmployeeSummaryInfoList(id, query) {
    return request({
        url: `/knowledge-shelf/report/${id}/employee-summary-info-list`,
        method: 'get',
        params: query
    });
};

export function getKnowledgeShelfReportDetails(id, query) {
    return request({
        url: `/knowledge-shelf/report/${id}/detail`,
        method: 'get',
        params: query
    });
};

export function getQrcode(id) {
    return requestRaw({
        url: `/knowledge-shelf/qrcode/${id}`,
        method: 'get',
        responseType: "blob"
    });
}