import request from '@/util/request';
import { requestRaw } from '@/util/request';
import qs from "qs";

export function getList(query) {
    return request({
        url: '/lightpage',
        method: 'get',
        params: query
    });
};

export function publish(id) {
    return request({
        url: "/lightpage/publish",
        method: "post",
        data: qs.stringify({ id })
    });
}

export function pause(id) {
    return request({
        url: "/lightpage/pause",
        method: "post",
        data: qs.stringify({ id })
    });
}

export function del(id) {
    return request({
        url: "/lightpage/" + id,
        method: "delete"
    });
}

export function getPageUrl(id) {
    return request({
        url: "/lightpage/getPageUrl/" + id,
        method: "get"
    });
}

export function getPreviewUrl(id) {
    return request({
        url: "/lightpage/getPreviewUrl/" + id,
        method: "get"
    });
}

export function find(id) {
    return request({
        url: "/lightpage/" + id,
        method: "get"
    });
}

export function saveAndPublish(params) {
    return request({
        url: "/lightpage/saveAndPublish",
        method: "post",
        data: qs.stringify(params)
    });
}

export function save(params) {
    return request({
        url: "/lightpage",
        method: "post",
        data: qs.stringify(params)
    });
}

export function exportExcel(pageId) {
    return requestRaw({
        url: "/lightpage/export?pageId=" + pageId,
        responseType: 'blob'
    });
}

export function getOfficialAccountList() {
    return request({
        url: "/channel/officialAccountList",
        method: "get"
    });
}

export function getLightpageColumns(pageId) {
    return request({
        url: '/lightpage/lightpageColumns',
        method: 'get',
        params: { pageId }
    });
};

export function getSubmitRecordList(query) {
    return request({
        url: '/lightpage/submitRecordList',
        method: 'get',
        params: query
    });
};

export function getReportDetail(pageId) {
    return request({
        url: '/lightpage/reportDetail',
        method: 'get',
        params: { pageId }
    });
};